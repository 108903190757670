body{
	overflow-x: hidden;
	color: #fff;
}

* p{
	margin-bottom: 10px;
}
.bg{
	background: url(img/nova88world-bg1.webp) no-repeat center center;
	background-size: cover; 
	height: 100vh;
	width: 100vw;
	position: fixed;
	z-index: 1;
}  

.bg .container{ 
	height: 100vh;
	display: flex;
	align-items: center;
}
.nova88_logo{
	color: #fff;   
}

.nova88_logo .nv88_img{
	width: calc(20vw + 20vh );
	height: auto; 
	
	-webkit-filter: drop-shadow(-1px 5px 5px #000);
    filter: drop-shadow(-10px 10px 5px #000);
}

.nova88_logo .byline{ 
	margin-bottom: 100px;
	text-shadow: -10px 10px 10px #000;
}

.location_logo ul{ 
	padding: 0;
	text-align: center; 
}

.location_logo ul li{
	display: inline-block;
	text-align: center; 
	cursor: pointer;
	margin-left: 1%;
	margin-right: 1%;
}

.location_logo ul li a{
	color: #FFFFFF;
	font-weight: bolder;
	cursor: pointer;
	text-decoration: none;
} 

.location_logo img{
	position: relative; 
	width: 110px;
	height: auto;
}


.header {
	position: relative; 
	padding: 10px 16px;  
	height: 100vh; 
} 
 
.content{
	position: relative; 
	z-index: 2;
	background: #fff; 
}

#footer{ 
	background: #112240;
	padding: 10px 0 10px 0;
} 

#footer .copyrights{
	position: relative;	
	text-align: center;
	padding: 20px 0 20px 0;
}

#content1{  
	padding: 5vh 0 5vh 0; 
	color: #fff;
	
}

#content2{  
	padding: 5vh 0 5vh 0;
	padding-bottom: 10px;  
	color: #fff;
	
}

#content3, #content2, #content1, #content4, #content5{  
	background: #112240; 
}

#content4{    
	padding-top: 30px;
	
} 
#content5{
	padding-top: 5vh;  
	color: #fff; 
	margin: 0;
} 

.content1, .content2{
	position: relative;
	width: 100%; 
}

.content1 .container{ 
	position: relative; 
	z-index: 3;
}  
.content1 .ambasador{
	position: relative;
	width: 50%;
	margin-bottom: 40px;
	opacity: .6;
	z-index:3;
}

.content1 .spokeperson{
	position: relative;
	width: 50%;
	z-index:3;
	opacity: .6;
}  

#content1 .jc-img-left{  
	position: absolute;  
	left: 0;
	top:0;
	z-index: 1;
}  

#content1 .jc-img-left-bottom{  
	position: absolute;  
	right: -1vw;
	bottom: 1px;
	z-index: 2;
}

#content1 .an-img-right{  
	position: absolute; 
	right: 0;
	top: 0; 
	z-index: 2;
} 

#content1 .an-img-right-bottom{  
	position: absolute; 
	right: -6vw;
	bottom: 1px; 
	z-index: 2;
}

 #content1 .jc-img-left img, #content1 .an-img-right img{ 
	height: 25%;
	width: auto;
} 

#content1 .jc-img-left-bottom img{ 
	height: 800px;
	width: auto; 
}
 
#content1 .an-img-right-bottom img{ 
	height:600px;
	width: auto; 
} 

#content1 .jc-text{
	margin-left: 18vw;
	margin-right: 15vw;
	z-index: 1;
}

#content1 .an-text{
	margin-left: 18vw;
	margin-right: 15vw;
	z-index: 1;
}
 
 
#content2 .row{
	opacity: 0.7;
} 


#content2 .slots ul{
	padding: 10px;
} 

.license{
	background: linear-gradient(120deg, #008ECC 23%,#F3F6F7 58%, #008ECC 100%);   
	padding-top: 30px; 
}

.license img{
	margin: 10px;
	height: 100px;
	width: auto;
} 

.license h2{
	color:#fff; 
	font-weight: bolder;
	background:rgba(85,116,167,0.8);
	margin: 0 auto;  
	padding: 10px;
	text-align: center;
	font-size: 15px;
}

.license h2 img{
	height: 30px;
	width: auto;
} 

.content5{
	position: relative;
	width: 100%;
	margin: 0 auto;   
}

.content5 p{
	opacity: 0.7; 
}

.content5 .reminder{
	margin: 0;
}

.items{
	width: 100%;
	height: 130px; 
	margin: 0 auto;
	padding: 0;
}

.items > div .card{  
	background: #5574a7;
	text-align: center; 
	padding-top: 10px; 
	width: 100%; 
	height: 100px;
} 

.items .slick-slide{
	padding: 10px;
}

.items .card img{  
	width:auto;
	height: 60px; 
	display: flex;
	margin: 0 auto; 
} 

.slots .row{
	padding: 10px;
}
.casino .row{
	padding: 10px;
}
.game .row{
	padding: 10px;
}

.items .card > div { 
		font-size: 12px; 
		width: 100%; 
		color: #fff;  
		padding-left: 20px;
		padding-right: 20px;
}

#content4 .ourpartners {
	height: 30px;
	width: 100%;
	color: #fff;
}

.ourpartners b:nth-Child(2){
	float: right;
}

#container-title{
	position: relative;
	font-size: 1.3em;
	font-weight: bold;
	min-height: 2.5em;
	
	z-index: 2;
}

#container-desc{
	position: relative;
	font-size: 1em;  
	z-index: 2;
}
 

.content5 .potential-reason { 
	width: 100%; 
	padding: 0;
	color: #000;  
} 

.content5 .potential-reason .reason-container{
	display:inline-block;
	vertical-align: top;   
	padding-top: 10px;
	padding-bottom: 10px;
	
}

.content5 .reason-card{
	width: 255px; 
	padding: 5px 5px 5px 5px;
	margin: 0 auto;
	height: 250px;
	width: 90%; 
	
	background: #fff;
	border-radius: 20px; 
}
.content5 .reason-card .reason-head{
	width: 100%;
	height: 80px;
	font-weight: bolder;
	text-align: center;  
	font-size: 1.1em;
	margin-bottom: 8px; 
	border-bottom: 1px solid #9e9e9e;
	color: #3D557A;
	padding-top: 20px;
	padding-bottom: 20px; 
} 

.reason-body{ 
	padding: 0 15px 0 15px;
	opacity: 0.7
}

.content5 .social{    
	font-weight: bold; 
	margin-bottom: 30px;
}

.social a{ 
	width: calc( 100% / 5); 
	padding: 0;
	display: inline-block;
	z-index: 5; 
	padding: 5px;
	color: #fff;
	text-decoration: none;
}

.content5 .social a > div{
	padding-bottom: 10px;
	border-radius: 10px;
}

.content5 .social a > div:hover{
	border: 1px solid #f2f2f2; 
}

.content5 .social a > div label{ 
	width: 100%;
	text-align: center;
	text-transform: uppercase;
	cursor: pointer;
}
.content5 .social a > div p{ 
	width: 100%;
	text-align: center;
	text-transform: italic;
}

.content5 > label{
	font-weight: bold;
}

.facebook{
	//background: linear-gradient( 168deg, #2F5CFF, #DBF4FF);
	background-color: #2b4170;
    background: -moz-linear-gradient(top, #3b5998, #2b4170);
    background: -ms-linear-gradient(top, #3b5998, #2b4170);
    background: -webkit-linear-gradient(top, #3b5998, #2b4170);
}

.instagram{
	background: linear-gradient( 168deg, #FF426C, #FF4B2B);
}

.twitter{
	background: linear-gradient( 168deg, #000, #444444);
}

.instagram{
	 background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%); 
}

.telegram{
	background: linear-gradient(#36AEE0,#1C96D1);
}

.youtube{
	background: #FF0000;
}

.content5 .social > a img{
	height: 60px; 
	padding: 12px;
	vertical-align: middle;
	 
}

.nova88_footer{
	width: 100%;
	height: auto;
}

 
@media ( max-width: 1399px ){   
	.location_logo img{
		position: relative; 
		width: 100px;
		height: auto;
	}
 
}

@media ( max-width: 1300px ){ 
	#content1 .jc-img-left-bottom{   
		right: 50px;
	} 

	#content1 .an-img-right-bottom{  
		right: -3vw;
	}  
}
 
@media ( max-width: 1200px ){ 
	#content1{
		display: block;
	}  
 
	.jc-an{ 
		position: relative;
		min-height: 550px;
		margin-bottom: 50px;
		border-bottom: 1px  solid #f8f8f8;
	}
	
	.content1{
		display: block;
	}
 	
	.content1 .ambasador{
		display: inline-block;
		padding-right: 10px;
	}
	
	.content1 .spokeperson{
		display: inline-block;
		float: right;
		padding-left: 10px;
	}   
	
	#content1 .jc-img-left-bottom{  
		position: absolute;  
		left: 0;
		bottom:0;
		z-index: 2;
	} 

	#content1 .an-img-right-bottom{  
		position: absolute; 
		right: 0;
		bottom: 0; 
		z-index: 2;
	} 

	#content1 .jc-img-left-bottom img{
		height: 600px;
		width: auto;
	}
	 
	#content1 .an-img-right-bottom img{ 
		height:450px;
		width: auto;
	}  
}

@media ( max-width: 991px ){
	.content2 .row{
		display: block;
	}
	.content2 .row > div{
		width: 100%;
	}
	
	.content5 .social{ 
		float: none;
		margin: 0 auto;
		height: auto;
		padding-bottom: 20px;
	}
	.content5{
		text-align: center;
	}
	
}

@media ( max-width: 767px ){
	
	.jc-an{ 
		position: relative;
		min-height: 450px;
		margin-bottom: 50px;
		border-bottom: 1px  solid #f8f8f8;
	} 

	#content1 .jc-img-left-bottom img{
		height: 450px;
		width: auto;
	}
	 
	#content1 .an-img-right-bottom img{ 
		height:350px;
		width: auto;
	}  
	
	.content5 .social a > div{
		padding-bottom: 0; 
	} 
	.content5 .social a > div label{ 
		display: none;
	}
	.content5 .social a > div p{ 
		display: none;
	}
 
}

@media ( max-width: 745px ){
    
	.nova88_logo .byline{ 
		margin-bottom: 50px; 
	} 

	.location_logo li{
		padding-bottom: 30px;
	}

	.location_logo img{
		position: relative; 
		width: 100px;
		height: auto;
	}

	.location_logo a{ 
		font-size: 10px;
	}
}

@media ( max-width: 605px ){
	
	.jc-an{ 
		position: relative;
		min-height: 330px;
		margin-bottom: 50px;
		border-bottom: 1px  solid #f8f8f8;
	} 

	#content1 .jc-img-left-bottom img{
		height: 350px;
		width: auto;
	}
	 
	#content1 .an-img-right-bottom img{ 
		height:270px;
		width: auto;
	}  
 	
	.content1 .ambasador{
		display: block; 
		width: 100%;
		padding: 5px;
		margin-bottom: 5px;
	}
	
	.content1 .spokeperson{
		display: block;
		float: none;
		width: 100%;
		padding: 5px;
	}    
	
}

@media ( max-width: 445px ){ 
	.license h2{    
		padding: 2px;
		text-align: center;
		font-size: 15px;
	}

	.license h2 img{
		height: 20px;
		width: auto;
	}   

	.nova88_logo .byline{ 
		margin-bottom: 50px; 
	} 

	.location_logo li{
		padding-bottom: 30px; 
		margin-left: 2% !important;
		margin-right: 2% !important;
	}

	.location_logo img{
		position: relative; 
		width: 70px;
		height: auto;
	}

	.location_logo a{ 
		font-size: 10px;
	}
	
	.jc-an{ 
		margin-bottom: 20px; 
	} 

	#content1 .jc-img-left-bottom img{
		height: 300px;  
	}
	 
	#content1 .an-img-right-bottom img{ 
		height:235px; 
	}  
	
	#content3 .container{
		width: 100vw;
		padding: 0;
	}
	
	#footer{
		padding-bottom: 0;
	}
	
	#footer img{
		width: 100vw;  
	}
	
	#footer .container{
		width: 100vw; 
		margin:0;
		padding:0; 
	}
}

@media ( max-width: 350px ){   
	.nova88_logo .byline{ 
		margin-bottom: 20px; 
		font-size: 1em;
	} 

	.location_logo li{
		padding-bottom: 20px;  
	}

	.location_logo img{  
		width: 60px;
		height: auto;
	}

	.location_logo a{ 
		font-size: 10px;
	}
	#content1 .jc-img-left-bottom img{
		 
	}
	 
	#content1 .an-img-right-bottom img{ 
		 
	}  
	
	#content1 .jc-img-left-bottom{   
		left: -50px; 
	}

	#content1 .an-img-right-bottom{   
		right: 0; 
	}  
	.jc-an{  
		min-height: 300px;  
	}
	
	.content5 .social{
		width: 100%;
	}
	
	.social ul{
		padding: 0;
	}
	.social ul li a{
		width: 50px;
		height: 50px;  
	} 
	
	.social ul li img{
		width: 50px;
		height: 50px  !important;
	}
}
 
 
@media (max-width: 262px){
	.nova88_logo .nv88_img{ 
		width: 100%;
		height: auto;
	} 
	#content1 .jc-img-left-bottom img{
		width: 100%;
		height: auto;
	}
	 
	#content1 .an-img-right-bottom img{  
		width: 100%;
		height: auto;
	}  
	#content1 .jc-img-left-bottom{   
		left: 0; 
		width: 100%
	}

	#content1 .an-img-right-bottom{   
		right: 0; 
		width: 100%
	}  
	.byline, .content1, .content2, .content3, .content4, .content5{
		word-wrap: break-word;
	}
	.content2 ul{
		padding: 0;
	} 
	.license img{
		width: 100%;
		height: auto;
		margin: 0;
	}
}
 
@media ( max-height: 794px ) and ( min-width: 445px ){
	.nova88_logo .byline{
		margin-bottom: 10px;
	}
	
	.nova88_logo img{
		width: 90px;
		height: auto;
	}
}

.slick-slide{
	height: auto;
}
#content2 .slick-dots { 
	list-style: none; 
	text-align: center; 
	padding: 0;
}
#content2 .slick-dots li{
	display: inline-block; 
	width: auto; 
	margin: 5px;
}

#content2 .slick-dots li button{
	width: 60px;
	height: 1px;
	font-size: 0;
	line-size: 0; 
	outline: none;
	cursor: pointer;
	background: transparent;
	border: 1px solid #808080; 
	border-radius: 5px; 
}

#content2 .slick-dots .slick-active button{
	background: #808080;
}
